@import './../../palette.scss';

.HeaderImage {
  background-image: url('/img/gallery-header.jpg');
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  height: 40vh;
}

.TextPhoto {
  position:absolute;
  width:100%;
  bottom:0%;
  padding: 1em;
  background-color:rgba(255, 255, 255, 0.7);
}

.QuotePhoto {
  position:absolute;
  width:100%;
  font-weight: 600;
  font-family: 'Wire One', sans-serif;
  letter-spacing: 0.1em;
  font-size: 1.5em;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  bottom: 0em;
}

.ImageBlock {
  position: relative;
  border: 1em black solid;
}

.Feature {
  background-color: black;
}

.HeaderBlock{
  position: relative;
}

.Wrapper {
  padding-bottom: 3em;
  padding-top: 3em;
  p{
    font-weight: 700;
    font-size: larger;
    font-style: italic;
    color: $eggplant;
  }
}

.Carousel {
  font-size: 200%;
  text-align: center;
  color: white;
}

.Haiku {
  display: inline-block;
  text-align: left;
}

.HaikuWrapper {
  padding-top: 2em;
  text-align: center;
}