@import './../../palette.scss';

.Container{
  position: relative;
  padding: 0;
  padding-top: 4em;
}

.Title {
  z-index: 800;
  position: absolute;
  left: 10%;
  margin-top: 5%;
  color: white;
  background-color: linear-gradient(147deg,red,yellow,green);
  .Job {
    font-family: 'Wire One', sans-serif;
    font-size: 4em;
    text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .Name {
    font-family: 'Playfair Display', serif;
    font-size: 4em;
    text-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
  .Desc {
    border-left: solid 2px $medium-spring-bud;
    padding-left: 2em;
    margin-top: 2em;
    margin-left: 4em;
  }
  @media screen and (max-width: 480px) {
    .Name {
      font-size: 3.5em;
    }
  }
}

.Wrapper {
  overflow: hidden;
  position: relative;
  z-index: 500;
  .LeftTri {
    top:0;
    left: -700px;
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    border-width: 650px 0px 0 1400px;
    border-color: $medium-dark-purple transparent transparent transparent;
    overflow: hidden;
  }
  .RightTri {
    top:0;
    left: 700px;
    position: relative;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 650px 3000px 0 0px;
    border-color: $medium-purple transparent transparent transparent;
  }
}

.Photo {
  max-width: 400px;
}

.ParticleWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0;
}

.Content {
  overflow: hidden;
  position: relative;
  z-index: 500;
  img {
    margin-top: 7em;
    border-radius: 50%;
    max-width: 90%;
    cursor: pointer;
    padding: 50px;
    clip-path: circle(calc(50% - 40px));
    --_p: 100px;
    --_g1: radial-gradient(50% 50%,#613f51 90%,#0000); /* color 1 */
    --_g2: radial-gradient(50% 50%,#799424 90%,#0000); /* color 2 */
    --_g3: radial-gradient(50% 50%,#cccd8a 90%,#0000); /* color 3 */
    --_g4: radial-gradient(50% 50%,#55548e 90%,#0000); /* color 4 */
    background:
      var(--_g1) calc(20% - var(--_p)) calc(20% - var(--_p)),
      var(--_g2) calc(80% + var(--_p)) calc(10% - var(--_p)),
      var(--_g3) calc(80% + var(--_p)) calc(80% + var(--_p)),
      var(--_g3) 55%                   calc(12% - var(--_p)),
      var(--_g1) calc(18% - var(--_p)) calc(88% + var(--_p)),
      var(--_g2) calc(10% - var(--_p)) calc(70% + var(--_p)),
      var(--_g2) calc(88% + var(--_p)) 40%,
      var(--_g1) calc(82% + var(--_p)) calc(28% - var(--_p)),
      var(--_g4) calc(12% - var(--_p)) 30%,
      var(--_g4) 65%                   calc(85% + var(--_p)),
      var(--_g3) calc(20% - var(--_p)) calc(10% - var(--_p)),
      var(--_g4) calc(30% - var(--_p)) calc(85% + var(--_p));
    background-size: 15px 15px, 20px 20px, 30px 30px;
    background-repeat: no-repeat;
    filter: grayscale(100%) brightness(80%) contrast(90%);
    transition: .5s;
    &:hover {
      filter: grayscale(0%) brightness(90%) contrast(100%);
      clip-path: circle(50%);
      --_p: 0px;
    }
  }
  h3 {
    font-size: 2.2em;
  }
  a {
    text-decoration: none;
    color: black;
  &:hover {
    color: $eggplant;
  }
  }
}

.Subscribe {
  overflow: hidden;
  position: relative;
  z-index: 500;
  margin-top: 8em;
  margin-bottom: 10em;
  img {
    width: 70%;
    max-width: 500px;
    background-color: $off-white;
    border-radius: 50%;
    border: 3px #ddd solid;
    margin-top: 2em;
    margin-bottom: 5em;
  }
  .Speech {
    margin-top: 3em;
    position: relative;
    background: $medium-dark-purple;
    border-radius: 2em;
    padding: 2em;
    color: white;
    h3 {
      color: $mustard;
    }
  }
}

form {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  input, textarea {
    height: 46px;
    padding: 1em;
    border: 3px solid #ddd;
    border-radius: 4px;
    outline: 0;
    transition: .2s;
    &:focus {
    border-color: $mustard;
    }
  }
  textarea {
    height: 92px;
    margin-bottom: -1em;
  }
  input {
    margin-top: 1em;;
  }
  button {
    padding: 12px 10px;
    border: 0;
    background: linear-gradient(to right, $mustard 0%, $medium-spring-bud 10%, $olive-drab 60%); 
    border-radius: 3px;
    margin-top: 10px;
    color: #fff;
    letter-spacing: 1px;
    font-family: 'Rubik', sans-serif;
    transition: .5s;
    &:hover {
      filter: sepia(100%);
      color: $eggplant;
    }
  }
}

@media (min-width: 768px) {
  .Speech {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 53px solid transparent;
      border-left-color: $medium-dark-purple;
      border-right: 0;
      border-bottom: 0;
      margin-top: -26.5px;
      margin-right: -53px;
    }
  }
  .Subscribe img {
    margin-top: 7em;
  }

}

