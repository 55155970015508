@import './../../palette.scss';

.Icon {
  z-index: 9999;
  cursor: pointer;
  &:hover { 
    transform: scale(1.1); 
  }
}

.Position{
  z-index: 9999;
  background-color: $darker-purple;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: fixed;
  width: 100%;
}

.Menu {
  position: absolute;
  right: 0;
  top:0;
  width: 300px;
  margin: -100px 0 0 -50px;
  padding: 50px;
  padding-top: 200px;
  background: $transparent-purple;
  list-style-type: none;
  z-index: 1999;
  border-radius: 0 0 0 5rem;
  a  {
  text-decoration: none;
  color:black;
  font-size: 1.5rem;
  font-weight: 600;
  }
  li{
    line-height: 2.5rem;
  }
}

.All {
  color: dimgray;
  &:hover{
    color:black;
  }
}