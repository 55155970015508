@import './../../palette.scss';

.Wallpaper {
  background-color: #f8f8ff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23c3b5e5' fill-opacity='0.26'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  h4, h1 {
    font-family: 'Sriracha', cursive;
  }
  td{
    font-size: large;
  }
}

.Photo {
  margin-top: 2em;
  width: 80%;
}

.AboutTop{
  background-color: $light-purple;
  border-bottom: 1px $medium-purple dotted;
  padding-top: 4em;
}

.Speech {
  margin-top: 7em;
  position: relative;
  background: $medium-dark-purple;
  border-radius: 2em;
  padding: 2em;
  color: white;
  h3 {
    color: $mustard;
  }
}

.Skills {
  margin-bottom: 10em;
}

@media (min-width: 768px) {
  .Speech {
    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 35%;
      width: 0;
      height: 0;
      border: 52px solid transparent;
      border-left-color: $medium-dark-purple;
      border-right: 0;
      border-top: 0;
      margin-top: -26px;
      margin-right: -52px;
    }
  }
}

.Pink {
  --paper-color: #ecb2ba;
  --paper-dark: rgb(198, 147, 154,0.5);
  --shadow-size: 1px;
  --transparent: rgba(236, 178, 186, 0);
  transform: rotate(-0.5deg);
}

.Blue {
  --paper-color: #d5e0f9;
  --paper-dark: rgb(194, 208, 234,0.5);
  --shadow-size: 3px;
  --transparent: rgba(213, 224, 249, 0);
  transform: rotate(0.5deg);
}

.Paper {
  padding: 3em;
  position: relative;
  font-weight: bold;
  background: linear-gradient(
      to bottom right,
      var(--paper-dark),
      20%,
      var(--transparent)
    ),
    var(--paper-color);
  min-width: 250px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-size) var(--shadow-size) 2px var(--paper-dark);
  margin: auto;
  margin-top: 50px;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        var(--transparent),
        50%,
        var(--paper-dark),
        51%,
        var(--transparent)
      ),
      linear-gradient(
        to right,
        var(--transparent),
        50%,
        var(--paper-dark),
        51%,
        var(--transparent)
      );
  }
}



.TapeSection {
  position: absolute;
  width: 100%;
}

.TopTape {
  position: absolute;
  height: 4vmin;
  top: -5px;
  width: 110%;
  background-color: #dbd8be;
  border-right: 1px dotted #b7b49d;
  border-left: 1px dotted #b7b49d;
  opacity: 0.5;
}

.TapeSection:first-of-type {
  top: 0;
}

.TapeSection:last-of-type {
  bottom: 0;
}

.TapeSection::before,
.TapeSection::after {
  content: "";
  width: 10vmin;
  height: 4vmin;
  position: absolute;
  background-color: #dbd8be;
  opacity: 0.5;
  border-right: 1px dotted #b7b49d;
  border-left: 1px dotted #b7b49d;
}

.TapeSection:last-of-type::after {
  transform: rotate(-45deg);
  right: -4vmin;
  top: -3vmin;
}

.TapeSection:first-of-type::before {
  transform: rotate(-45deg);
  left: -4vmin;
}

.TapeSection:first-of-type::after {
  transform: rotate(45deg);
  right: -4vmin;
  top: 0;
}

.TapeSection:last-of-type::before {
  transform: rotate(45deg);
  left: -4vmin;
  bottom: 0;
}