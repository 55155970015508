@import './../../palette.scss';

.Wrapper {
  max-width: 100%;
  align-items: flex-start;
  margin: 2em 0;
  list-style-type: none;

  .Author{
    font-style: oblique;
  }

  .Content {
    
  }

  .Tags {
    a {
      margin-right: 0.3em;
      padding: 0.1em 0.4em 0.4em 0.4em;
      border-radius: 0.5em;
      font-size: smaller;
      font-style: oblique;
      background-color: $mustard;
      color: $eggplant;
    }
  }
}
