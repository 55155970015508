@import './../../palette.scss';

.Wrapper {
  min-height:18em;
  position: relative;
  a {
    text-decoration: none;
    color: white;
  }
  h4 {
    margin-bottom: -10px;
    margin-top: 1em;
  }
  h5 {
    font-weight: 300;
    font-style: italic;
    padding: 1em;
  }
  hr {
    width: 60px;
    background-color: $mustard;
    height: 2px
  }
}

.Quote {
  text-align: right;
}

.Footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: red;
  color: white;
  background-color: $dark-purple;
  z-index: 200;
}

.Top {
  background-color: $light-dark-purple;
}

.Bottom {
  background-color: $darker-purple;
  margin-bottom: 0;
  padding: 1em;
}