@import './../../palette.scss';

.wrapper {
  display: flex;
  gap: 0.8em;
  font-size: small;
  align-items: center;
  list-style-type: none;
  .paginationItem {
    display: flex;
    button {
      border: none;
      background: $off-white;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      overflow: hidden;
      color: rgb(0, 0, 0);
      &:hover {
        background-color: $light-purple;
        cursor: pointer;
        color: white;
        font-weight: 700;
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.2;
      }
    }
    &[aria-current="page"] {
      button {
        background-color: $medium-purple;
        color: white;
        font-weight: 700;
      }
    }

    .arrowButton {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1em;
        height: 1em;
      }
    }
  }

  .dots {
    line-height: 34px;
    margin: 5px 10px;
  }
}

.selectPage {
  padding: 0.1em 0.4em 0.3em 0.4em;
  margin-left: 2em;
  margin-top: 0.2em;
  border: 1px solid #E8EAED;
  border-radius: 5px;
  background: white;
  box-shadow: 0 1px 3px -2px #9098A9;
  cursor: pointer;
  font-family: inherit;
  font-size: 16px;
  transition: all 150ms ease;
  &:focus{
    border: 2px lightgray solid;
  }
  &:hover{
    border: 2px lightgray solid;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    gap: 0.2em;
    font-size: smaller;
    .paginationItem {
      display: flex;
      button{
        height: 30px;
        width: 22px;
        border-radius: 0.1em;
      }
    }
  }
  .selectPage {
    font-size: small;
    margin-left: 1em;
    margin-right: 3em;
  }
}