@import './../../palette.scss';

.carousel {
  display: flex;
  height: 700px;
  width: 100%;
}

.carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.9);
  transition: 0.5s ease-in-out;
}

.carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.card_image {
  width: 100%;
  object-fit: cover;
}

.card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}

.card_title {
  color: white;
  font-size: 0.8em;
  border-radius: 0 0 1em 1em;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.4em;
  margin: 0;
}

.carousel_arrow_left,
.carousel_arrow_right {
  position: absolute;
  font-size: 50px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding-bottom: 16px;
  cursor: pointer;
}

.carousel_arrow_left {
  left: 15px;
}

.carousel_arrow_right {
  right: 15px;
}

.carousel_pagination {
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: $eggplant;
}