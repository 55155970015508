$dark-purple: #121112;
$darker-purple: #201e25;
$light-dark-purple: #29272e;
$medium-dark-purple: #515061;
$medium-purple: #5e5e69;
$dark-puce: #413b40;
$eggplant: #5a5156;
$medium-spring-bud: #b9b9ab;
$olive-drab: #80866b;
$logo: #f4eeff;
$light-purple: #c1bccc;
$transparent-purple: rgba(219, 214, 235, 0.85);
$mustard: #d6c8aa;
$off-white: #f8f8ff;